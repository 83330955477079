/**
 * Main Navigation functionality.
 *
 * @module feature-navigation
 * @version 1.0.0
 * @author Rocco Janse, rocco.janse@valtech.nl
 */
import { viewport } from 'foundation-theming';
import { debounce } from '@valtech/utilities';

const flyouts = [];

/**
 * Updates the flyout widths based on window width.
 * @returns void
 */
function updateFlyouts() {
  const defaultLeftOffset = 80;

  if (viewport.is('>=lg')) {
    // get first item (key) of flyouts map
    const [firstItem] = flyouts;

    if (!firstItem) {
      return;
    }

    const firstItemLeftOffset = firstItem.getBoundingClientRect().left;

    flyouts.forEach((flyoutNode) => {
      const flyout = flyoutNode;
      if (flyout) {
        flyout.style.left = `${-defaultLeftOffset}px`;
        flyout.style.width = `${window.innerWidth - firstItemLeftOffset + defaultLeftOffset}px`;
      }
    });
  }
}

// update flyouts on window debounced resize event
window.addEventListener('resize', debounce(updateFlyouts, 200), false);

// on document ready
document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.list-inline-item--flyout .main-navigation__flyout'))
    .forEach(flyout => flyouts.push(flyout));

  updateFlyouts();
});
